import React, { useState, useEffect } from "react"
import styles from "./styles"
import { makeStyles } from "@material-ui/styles"
import axios from "axios"

import iconPeople from "../../../images/prod-icon-people.webp"
import iconBoxes from "../../../images/prod-icon-boxes.webp"
import iconTime from "../../../images/prod-icon-time.webp"
import iconDiff from "../../../images/prod-icon-difficulty.webp"
import Reviews from "./Reviews"

export default function ProductInfoAndSpec({
  productData,
  productMeta,
  contains,
  sku,
  descriptionTab,
  setDescriptionTab,
}) {
  const useStyles = makeStyles(theme => styles(theme))
  const classes = useStyles()
  const [reviews, setReviews] = useState()

  const [activeVideo, setActiveVideo] = useState(0)

  const handleClick = (e, index) => {
    e.preventDefault()
    setDescriptionTab(index)
  }

  const { title, variants, description } = productData
  const {
    features,
    dimensions,
    materials,
    personsToAssemble,
    assemblyTime,
    assemblyDifficulty,
    shippingBoxes,
    careAndMaintenance,
    videos,
    pdfs,
  } = productMeta

  const variant = variants.find(v => v.sku === sku)

  useEffect(() => {
    axios
      .get(
        `https://api.feefo.com/api/20/reviews/product?merchant_identifier=zest&product_sku=${sku}`
      )
      .then(res => setReviews(res.data.reviews))
  }, [])

  useEffect(() => {
    function retryScrollUntilDone() {
      setTimeout(() => {
        var elem = document.getElementById("feefo-reviews");
        if (elem) {
          window.scrollTo({
            top: elem.offsetTop - 100,
            behavior: "smooth",
          });
        } else {
          retryScrollUntilDone();
        }
      }, 200);
    }

    if (window.location.hash === "#feefo-product-review-widgetId") {
      if (descriptionTab !== 3) {
        setDescriptionTab(3);
      }
      retryScrollUntilDone();
    }
  }, []);

  return (
    <div className={`info-and-spec ${classes.infoAndSpec}`}>
      <div className="tab-nav">
        <div className="wrapper">
          <div className="tab-wrap">
            <button
              onClick={e => {
                handleClick(e, 0)
              }}
              className={`${descriptionTab === 0 ? "active" : ""}`}
            >
              Description
            </button>
            <button
              onClick={e => {
                handleClick(e, 1)
              }}
              className={`${descriptionTab === 1 ? "active" : ""}`}
            >
              Specification
            </button>
            {(personsToAssemble ||
              assemblyTime ||
              assemblyDifficulty ||
              shippingBoxes ||
              careAndMaintenance ||
              videos ||
              pdfs) && (
              <button
                onClick={e => {
                  handleClick(e, 2)
                }}
                className={`${descriptionTab === 2 ? "active" : ""}`}
              >
                Assembly
              </button>
            )}
            {reviews && reviews.length > 0 && (
              <button
                onClick={e => handleClick(e, 3)}
                className={`${descriptionTab === 3 ? "active" : ""}`}
              >
                Reviews
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="tabs">
        <div className="wrapper">
          {descriptionTab === 0 && (
            <div className="overview">
              <h3>Overview</h3>
              <div dangerouslySetInnerHTML={{ __html: description }}></div>
            </div>
          )}
          {descriptionTab === 0 && features && (
            <div className="features">
              <h3>Features</h3>
              <ul>
                {features.map((feature, index) => (
                  <li key={index}>
                    <span>{feature.text}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {descriptionTab === 1 && (
            <div className="product-sepc">
              <h3>Dimensions &amp; weight</h3>

              <table>
                <th>
                  <td>
                    {title} / {variant.sku}
                  </td>
                </th>
                {dimensions && (
                  <tr>
                    <td>Sizes</td>
                    <td dangerouslySetInnerHTML={{ __html: dimensions }}></td>
                  </tr>
                )}
                {materials && (
                  <tr>
                    <td>Materials</td>
                    <td>{materials}</td>
                  </tr>
                )}
                {variant.weight && variant.weight !== "0 lb" && (
                  <tr>
                    <td>Weight</td>
                    <td>{variant.weight}</td>
                  </tr>
                )}
              </table>

              {contains &&
                contains.map(c => (
                  <table>
                    <th>
                      <td>
                        {c.ProductData.title} / {c.ProductData.variants[0].sku}
                      </td>
                    </th>
                    {c.ProductMeta.dimensions && (
                      <tr>
                        <td>Sizes</td>
                        <td>{c.ProductMeta.dimensions}</td>
                      </tr>
                    )}
                    {c.ProductMeta.materials && (
                      <tr>
                        <td>Materials</td>
                        <td>{c.ProductMeta.materials}</td>
                      </tr>
                    )}
                    {c.ProductData.variants[0].weight && (
                      <tr>
                        <td>Weight</td>
                        <td>{c.ProductData.variants[0].weight}</td>
                      </tr>
                    )}
                  </table>
                ))}
            </div>
          )}

          {descriptionTab === 2 && (
            <>
              <div className="assembly-care">
                {(personsToAssemble ||
                  assemblyTime ||
                  assemblyDifficulty ||
                  shippingBoxes) && (
                  <>
                    <h3>How to put it together</h3>
                    <div className="icons">
                      {personsToAssemble && (
                        <span>
                          <img src={iconPeople} alt="" loading="lazy" />{" "}
                          {personsToAssemble}{" "}
                          {personsToAssemble === "1" ? "person" : "people"}
                        </span>
                      )}
                      {assemblyTime && (
                        <span>
                          <img src={iconTime} alt="" loading="lazy" />{" "}
                          {assemblyTime}
                        </span>
                      )}
                      {assemblyDifficulty && (
                        <span>
                          <img src={iconDiff} alt="" loading="lazy" />{" "}
                          {assemblyDifficulty}
                        </span>
                      )}
                      {shippingBoxes && shippingBoxes !== "pallet" && (
                        <span>
                          <img src={iconBoxes} alt="" loading="lazy" />{" "}
                          {shippingBoxes}{" "}
                          {shippingBoxes === "1" ? "box" : "boxes"}
                        </span>
                      )}
                      {shippingBoxes && shippingBoxes === "pallet" && (
                        <span>
                          <img src={iconBoxes} alt="" loading="lazy" /> Pallet
                        </span>
                      )}
                    </div>
                  </>
                )}

                {careAndMaintenance && (
                  <>
                    <h3>Care &amp; maintenance</h3>

                    <p>{careAndMaintenance}</p>
                  </>
                )}
              </div>

              <div className="video-docs">
                {videos && videos.length > 0 && (
                  <div className="video">
                    {videos.map((video, index) => {
                      const id = video.url
                        .split("/")
                        .pop()
                        .replace("watch?v=", "")
                      if (activeVideo === index) {
                        return (
                          <div className="res-video" key={id}>
                            <iframe
                              width="420"
                              height="315"
                              src={`https://www.youtube.com/embed/${id}`}
                              key={id}
                              title="Assembley video"
                            />
                          </div>
                        )
                      }

                      return null
                    })}
                    <div className="actions">
                      {videos.length > 1 &&
                        videos.map((video, index) => {
                          return (
                            <button
                              onClick={() => setActiveVideo(index)}
                              data-active={activeVideo === index ? true : false}
                            >
                              Assembly Video {index + 1}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6.76"
                                height="10.692"
                                viewBox="0 0 6.76 10.692"
                              >
                                <path
                                  d="M1497.349-439l-3.932,3.932L1489.485-439"
                                  transform="translate(440.414 1498.763) rotate(-90)"
                                  strokeLinecap="round"
                                  strokeWidth="2"
                                />
                              </svg>
                            </button>
                          )
                        })}
                    </div>
                  </div>
                )}

                {pdfs && pdfs.length > 0 && (
                  <div className="pdfs">
                    <h3>PDF Guides</h3>

                    {pdfs.map((pdf, index) => (
                      <a
                        href={pdf.url}
                        target="_blank"
                        key={index}
                        rel="noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="17"
                          viewBox="0 0 14 17"
                        >
                          <g
                            id="Group_1538"
                            data-name="Group 1538"
                            transform="translate(-5 -3)"
                          >
                            <path
                              id="Path_5228"
                              data-name="Path 5228"
                              d="M16.59,9H15V4a1,1,0,0,0-1-1H10A1,1,0,0,0,9,4V9H7.41a1,1,0,0,0-.71,1.71l4.59,4.59a1,1,0,0,0,1.41,0l4.59-4.59A1,1,0,0,0,16.59,9ZM5,19a1,1,0,0,0,1,1H18a1,1,0,0,0,0-2H6A1,1,0,0,0,5,19Z"
                              fill="#a1d884"
                            />
                          </g>
                        </svg>
                        Assembly Instructions
                      </a>
                    ))}
                  </div>
                )}
              </div>
            </>
          )}

          {descriptionTab === 3 && <Reviews sku={variant.sku} />}
        </div>
      </div>
    </div>
  )
}
