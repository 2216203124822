import React, { useEffect, useState } from "react"
import axios from "axios"

export default function Reviews({ sku }) {
  const [reviews, setReviews] = useState()

  useEffect(() => {
    axios
      .get(
        `https://api.feefo.com/api/20/reviews/product?merchant_identifier=zest&product_sku=${sku}`
      )
      .then(res => setReviews(res.data.reviews))
  }, [])

  if (!reviews) return <div>Loading...</div>
  if (reviews.length === 0) return <div>There is no reviews yet</div>
  return (
    <>
      <div className="star-col" id="feefo-reviews">
        <img
          alt="Feefo logo"
          border="0"
          src={`https://api.feefo.com/api/logo?merchantidentifier=zest&template=Product-Stars-White-Square-250x250.png&smallonzero=true&vendorref=${sku}`}
          title="Our customer Feefo rating"
          loading="lazy"
        />
      </div>
      <div className="review-col">
        {reviews.map(review => {
          const month = new Date(review.products[0].created_at).toLocaleString(
            "default",
            {
              month: "long",
              year: "numeric",
            }
          )

          return (
            <div key={review.products[0].created_at} className="review">
              <p className="name">Anonymous</p>
              <div
                className="feefo-product-stars-widget"
                data-product-sku={sku}
              ></div>
              <p className="date">{month}</p>
              <p className="body">{review.products[0].review}</p>
            </div>
          )
        })}
      </div>
    </>
  )
}
